.search-result-section {
    width: 100%;
    padding: 0px 30px 30px;
}

.back-home-wrap {
    text-align: left;
    margin-bottom: 15px;
}

.back-home-wrap button {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #016288;
}

.back-home-wrap button span {
    color: #C2CFE0;
    font-size: 22px;
    margin-right: 8px;
    line-height: 0;
}

.Search-result-title-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.Search-title {
    font-family: Helvetica Neue;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 28px;

}

.serched-result {
    font-family: Helvetica Neue;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

}

.pdf-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.pdf-list-icons {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.status-wrap {
    padding: 8px 11px;
    border: 1px solid rgba(194, 207, 224, 1);
    border-radius: 35px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
    font-family: Helvetica Neue;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-right: 4px;

}

.status-wrap img {
    margin-right: 7px;
    width: 12px;
    height: 12px;
}

.table-user-li {
    margin-bottom: 0px;
}

.table-user-li .user-picture-wrap {
    width: 16px;
    height: 16px;
}