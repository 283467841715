.profile-section {
    padding: 30px;
}

.profile-section .info-wrap {
    background: #FFF;
    box-shadow: 0px 6px 18px 0px #0000000F;
    border: 1px solid #F5F6F8;
    padding: 24px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.org-images-wrap {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    display: block;
    position: relative;
}

.org-images-wrap .btn-upload-camera {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #FFF;
    line-height: 0;
    border: 0;
    background: #FCAF18;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
}
.org-images-wrap .org-img-wrap img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.org-images-wrap .org-img-wrap .p-name {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #f5f6f8;
    font-size: 22px;
    color: #016288;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-section .info-wrap .info-title {

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    color: #334D6E;
    margin-bottom: 0;
}

.profile-section .info-wrap .pass-title {

    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
}

.profile-section .info-wrap .btn-style {
    background: #FFF;
    border: 0px;
    font-size: 15px;
    line-height: 0;
}

.profile-section .info-wrap .btn-style .info-title {
    font-size: 15px;
    line-height: 15px;
    margin-left: 8px;
}

.profile-section .info-wrap .acc-btn {
    width: 25px;
    height: 25px;
    display: inline-block;
    background: #F5F6F8;
    border: 1px solid #C2CFE0;
    text-align: center;
    color: #334D6E;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.profile-section .info-wrap .number {
    color: #000;
}

.user_info {
    width: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 18px;
}

.user_info h2 {
    font-size: 20px;
    color: #334D6E;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 8px;
}

.user_info p {
    margin-bottom: 0;
    font-size: 14px;
    color: #334D6E;
    opacity: 0.9;
    line-height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.user_info p span {
    color: #334D6E;
    margin-right: 5px;
    line-height: 0;
    font-size: 15px;
}

.account-table {
    width: 80%;
    margin-bottom: 20px;
    border-spacing: 15px;

}

.account-table tbody tr td {
    padding: 5px 0;
    font-size: 14px;
    color: #334D6E;
}

.cancel-sub-btn {
    background: transparent;
    padding: 17px 32px;
    color: rgb(235, 63, 33);
    border: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-right: 0px;
}

.price_wrap {
    width: 100%;
    text-align: center;
    padding-top: 10px;
}

.price_wrap p {
    text-align: center;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-weight: 600;
    color: #515978;
}

.price_wrap p .price-span {
    font-size: 25px;
    font-weight: 600;
    color: #0d2366;
}

.success_wrap, .success_wrap .icon-wrap {
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
}

.success_wrap {
    flex-direction: column;
}

.success_wrap .icon-wrap {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    color: #fff;
    background-color: green;
    margin: 0 auto;
}

.success_wrap .icon-wrap svg {
    fill: #FFF;
    width: 30px;
    height: 30px;
}

.success_wrap p {
    text-align: center;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #7b8199;
    margin-top: 15px;
}

.details_pay, .success_wrap p {
    width: 100%;
    display: inline-block;
    position: relative;
}

.details_pay {
    padding-left: 10px;
    margin: 35px 0 15px;
}

.details_pay li {
    width: 100%;
    display: inline-block;
    position: relative;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #7b8199;
    margin-bottom: 5px;
}

.details_pay li span {
    color: #000;
    font-weight: 700;
}

.last-text {
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: center;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #7b8199;
    margin-bottom: 5px;
}

.last-text a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

@media screen and (max-width:767px) {
    .activiti_section{
        padding: 15px;
    }
}