.setting-link.is-active {
    background: linear-gradient(180deg, #F5F6F8 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 12px 12px 0px 0px;
    padding: 20px 18px;
    font-weight: 700;
    color: #016288;
    border: 1px solid #C2CFE0;
    /* border-bottom-color: transparent; */
    border-bottom: 1px solid #FFF !important;
}

.setting-link:hover {
    color: #334D6E;
}

.setting-link.is-active .custom-tab {
    border-bottom: 1px solid #FFF;
}

.setting-link {
    padding: 20px 16px 24px;
    font-weight: 700;
    text-decoration: none;
    color: #334D6E;
    position: relative;
    bottom: -1px;

}

.setting-page-padding {
    padding: 30px;
}

.organization-wrap {
    max-width: 600px;
    width: 100%;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 24px;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px 0px #0000000F;
    margin-bottom: 24px;
}

.organization-wrap-edit {
    max-width: 100%;
    /* max-width: 563px; */
    margin: 0 12px;
}

.organization-wrap-edit:first-child {
    margin-left: 0;
}

.organization-wrap-edit:last-child {
    margin-right: 0;
}

.org-img-wrap {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin: 0px 24px 0 0;
}

.organization-wrap .org-img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.organization-wrap .org-img-wrap .p-name {
    font-style: normal;
    font-weight: 500;
    font-size: 29px;
    line-height: 34px;
    color: #016288;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F8;
}

.organization-wrap .org-label-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #016288;
    opacity: 0.8;
}

.organization-wrap .org-table-title {
    font-family: Helvetica Neue;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-align: left;
    margin: 0px 0px 0px;
}

.organization-wrap .org-table-text {
    font-family: Helvetica Neue;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;

}

.org-detail {

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-top: 42px;
    margin-bottom: 16px;
}

.organization-wrap table>tbody>tr>td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #016288;
    padding-right: 24px;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.organization-wrap label {
    color: #016288;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 4px;
}

.organization-wrap input {
    border: 1px solid #C2CFE0;
    width: 100%;
    padding: 14px 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin-bottom: 14px;

}

.organization-wrap input[type="file"] {
    border: 0px;
    padding: 0px;
}

::-webkit-file-upload-button {
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    background: #016288;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 8px 14px;
    color: #FFF;
    border: 0px;

}

.edit-title {
    font-family: Helvetica Neue;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 32px;


}

.org-btn {
    padding: 17px 32px !important;
}

.cancel-btn {
    padding: 17px 32px;
    border-radius: 6px;
    color: #016288;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid #C2CFE0;
    background: #F5F6F8;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-right: 16px;
}

.change-btn {
    background: #016288;
    padding: 17px 32px;
    border-radius: 6px;
    color: #016288;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #FFF;
    border: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-right: 0px;
}

/* user-page-css */

.setting-user-btn {
    border: 0px;
    padding: 13px 32px;
    background: #016288;
    color: #FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: right;
    margin-right: 16px;
    display: inline-block;
    cursor: pointer;
}

.setting-user-li-wrap {
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px 0px #0000000F;
    padding: 24px;
}

.user-img-wrap {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-right: 19px;
}

.user-img-wrap img {
    width: 100%;
    height: 100%;
}

.setting-user-li-wrap .item {
    margin-top: 16px;
}


.member {
    /* margin-right: auto; */
    border-bottom: 1px solid #C2CFE0;
    display: flex;
    align-items: center;
    width: calc(100% - 67px);
    justify-content: space-between;
    padding-bottom: 20px;
}

.setting-user-li-wrap .item:last-child .member {
    border-bottom: 0px;
    padding-bottom: 0px;
}

.title--bold {
    font-family: Helvetica Neue;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;

    margin-bottom: 4px;
}

.title-text {
    font-family: Helvetica Neue;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 0px;
}

.btn-hover-underline {

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #016288;
}

.hr {
    width: 1px;
    height: 14.000000000000012px;
    background-color: #016288;
    display: inline-block;
    /* border: 1px solid  */

}

.btn-hover-underline {

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #016288;
}

.heading--active {

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 32px;
}

.checkinout .check-success--title>span {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #334D6E !important;
    margin-bottom: 40px !important;
}

.checkinout .check-success--title.add-ip-title>span {
    margin-bottom: 0 !important;
}


.checkinout .check-input {
    margin-bottom: 24px;
}

.checkinout .interactive {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #FCAF18;

}

.check-button button:first-child {
    padding: 17px 32px;
    border-radius: 6px;
    color: #016288;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid #C2CFE0;
    background: #F5F6F8;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-right: 16px;
}

.check-button button:last-child {
    background: #016288;
    padding: 17px 32px;
    border-radius: 6px;
    color: #016288;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #FFF;
    border: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: center;
}

.add-user-modal .task-popup {
    padding: 24px;
}

/* user-page-css-over */
/* druvi css */
.disable-back {
    background: #C2CFE0 !important;
}

.settings-settings-section .active {
    background: #016288 !important;

}

.setting-accordian .custom-item {
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px 0px #0000000F;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    margin-bottom: 20px;
}

.setting-accordian .custom-accordian input[type="checkbox"] {
    position: relative;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    background: #016288 !important;
    -webkit-border-radius: 2px;
}

.setting-accordian .accordian .accordian-body input {
    margin-right: 15px !important;
}

.setting-accordian .accordian-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #334D6E;
    margin-left: 8px;

}

.setting-accordian .accordian-header {
    background: #FFF !important;
    border-bottom: 1px solid #C2CFE0;
    padding: 16px 24px;
    margin-bottom: 0px;
}

.setting-accordian .accordion-item .accordion-button {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    background: #FFF;
    outline: none !important;
    box-shadow: unset !important;
}



.accordion-button:not(.collapsed) {
    /* color: #0c63e4; */
    background-color: #FFF;
    box-shadow: 0px;
}


.setting-accordian .accordion-button:not(.collapsed)::after {
    flex-shrink: 0;
    width: 18px;
    height: 12px;
    content: "";
    background: url('../images/acc-arrow.svg');
    transform: rotate(-180deg) translate(0px, -50%);
    background-repeat: no-repeat;
    background-size: 16px;
    position: absolute;
    top: 30%;
    right: 15px;
}

.settings-settings-section {
    position: relative;
}

.search-manage-file {
    position: absolute;
    right: 0;
    top: 0px;
    width: 100%;
    max-width: 330px;
}

.search-manage-file .search-input2 {
    width: 100%;
    background: #F5F6F8 url('../images/search-icon.svg') no-repeat center left 16px;
    /* box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06); */
    border-radius: 10px;
    padding: 11.5px 36px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #016288;
    border: 0;
}

.search-manage-file .search-input2::placeholder {
    color: #C2CFE0;
}

.setting-accordian .accordion-button::after {
    content: "";
    flex-shrink: 0;
    width: 18px;
    height: 12px;
    background-image: url('../images/acc-arrow.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    transform: rotate(0deg) translate(0px, -50%);
    transition: transform .2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 15px;
}

.c-table {
    /* display: block;  */
    width: 100%;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px 0px #0000000F;
    /* padding: 31px 24px; */
    /* margin: 31px 24px; */
    /* height: 100%; */
}

.c-table thead tr {
    border-bottom: 1px solid #C2CFE0;
}

.c-table thead tr th {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #9EA8B6;
    padding: 16px 24px 8px;
}

.c-table tbody tr td {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #334D6E;
    padding: 17px 24px;
}

/* druvi css over */
/* groups */
.groups-cards {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: 24px;
}

.groups-cards.isEdit-wrap {
    padding: 24px;
    margin-top: 0;
}

.group-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
}

.group-item.is_selected,
.group-item:hover {
    background: #F5F6F8;
}

.group-item .image-user-wrap {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 24px;
}

.group-item .image-user-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.group-item .image-user-wrap .p-name {
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F8;
    color: #016288;
}

.group-item:hover .image-user-wrap .p-name,
.group-item.is_selected .image-user-wrap .p-name {
    background: #FFF;
}

.group-item .group-content-wrap {
    width: calc(100% - 96px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.group-item .group-content-wrap .text-content-wraps {
    width: calc(100% - 100px);
}


.group-item .group-content-wrap .title-group {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 4px;
}

.group-item .group-content-wrap .group-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #334D6E;
    opacity: 0.5;
    margin-bottom: 2px;
}

.member-of-groups {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.member-of-groups .member-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.5;
    margin-bottom: 0;
    margin-right: 8px;
}

.member-of-groups .group_members {
    padding-left: 0;
    margin-bottom: 0;
    width: calc(100% - 72px);
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.member-of-groups .group_members li {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F5F6F8;
    border-radius: 50%;
    margin-left: -6px;
    position: relative;
    overflow: hidden;
}

.member-of-groups .group_members li.plus-label {
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    line-height: 9px;
    letter-spacing: 0.01em;
    color: #016288;
}

.member-of-groups .group_members li:first-child {
    margin-left: 0;
}

.member-of-groups .group_members li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.member-of-groups .group_members li .p-name {
    font-weight: bold;
    font-size: 8px;
    line-height: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #C2CFE0;
    color: #016288;
}

.group-actions-btns {
    /* position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0%, -50%); */
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.group-item.is_selected .group-actions-btns,
.group-item:hover .group-actions-btns {
    display: flex;
}

.group-actions-btns button {
    background: transparent;
    padding: 0;
    border: 0;
    cursor: pointer;
    margin-left: 12px;
}

.group-actions-btns button:first-child {
    margin-left: 0;
}

.group-actions-btns button svg path {
    stroke: #9EA8B6;
}

.group-actions-btns button:hover svg path {
    stroke: #FCAF18;
}

.groups-thumbnail {
    width: 100%;
    max-width: 72px;
    position: relative;
    display: inline-block;
    margin-bottom: 16px;
}

.groups-thumbnail .avatar-wraps-group {
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 50%;
}

.groups-thumbnail .avatar-wraps-group img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.groups-thumbnail .avatar-wraps-group .default_avatar {
    font-weight: bold;
    font-size: 25px;
    /* line-height: 16px; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #C2CFE0;
    color: #016288;
}

.groups-thumbnail .upload-bton {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
    border: 0;
    background: #FFF;
    border-radius: 50%;
    box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.groups-thumbnail .upload-bton i {
    font-size: 15px;
    color: #334D6E;
    line-height: 0;
}

.groups-button-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
}

.groups-button-block button {
    padding: 18px 24px;
    background: #F5F6F8;
    border: 1px solid #C2CFE0;
    border-radius: 6px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016288;
    margin-right: 16px;
}

.groups-button-block button:first-child {
    background: #016288;
    color: #FFF;
}

.groups-button-block button:last-child {
    margin-right: 0;
}

.form-title-group {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 32px;
}

.admin-super-wrap {
    width: 100%;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.card-main-wrap {
    width: 100%;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.f-table {
    width: 100%;
}

.f-table tbody tr td {
    padding: 0px 0 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #334D6E;
    opacity: 0.5;
}

.f-table tbody tr td.primary-color {
    color: #016288;
    opacity: 0.8;
    text-align: left;
}

.security-buttons-groups-ligth {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}

.security-buttons-groups-ligth button {
    background: #F5F6F8;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 17px 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016288;
    margin-right: 16px;
}

.security-buttons-groups-ligth button:last-child {
    margin-right: 0;
}

.security-buttons-groups-ligth button.bg {
    background: #016288;
    border-color: #016288;
    color: #FFF;
}

/* reports */
.chart-card {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 16px;
}

.title_chart {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    width: 100%;
    display: block;
}

.chart-wrap-details {
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    column-count: 4;
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.chart-wrap-details li {
    list-style: none;
    width: 100%;
    min-width: 95px;
    max-width: 117px;
    padding: 14px 16px;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
}

.chart-wrap-details li img {
    margin-bottom: 10px;
}

.chart-wrap-details li img.folder {
    width: 32px;
}

.chart-wrap-details li p {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.chart-wrap-details li p span {
    display: block;
    min-height: 29px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 10px;
}

.icon-chart-box {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-right: 8px;
    background: #FFF;
}

.icon-chart-box.yellow {
    background: #FCAF18;
}

.icon-chart-box.gray {
    background: #F5F6F8;
}

.text-langend-light {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.text-langend-light span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: block;
    margin-bottom: 2px;
}

.langend-wraps-chart {
    width: 100%;
    max-width: 337px;
    margin: 40px auto 56px;
    display: flex;
    column-count: 3;
    column-gap: 48px;
}

.legend-chart {
    width: 100%;
    max-width: 238px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* flex-direction: column; */
    margin: 37px auto 21px;
    flex-wrap: wrap;
    padding: 8px;
}

.legend-chart .legend-item {
    width: calc(100% / 2);
}

.legend-chart .legend-item .icon {
    border-radius: 2px;
}

.legend-chart .legend-item .label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.line-charts-reports {
    width: 100%;
    min-height: 350px;
    margin: 25px 0;
}

.legend-line-report {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    position: relative;
}

.legend-line-report .legend-item-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 12px;
}

.legend-line-report .legend-item-line:last-child {
    margin-right: 0;
}

.legend-line-report .legend-item-line .icon-style {
    width: 12px;
    height: 12px;
    display: inline-block;
    position: relative;
    margin-right: 9px;
    border-radius: 50%;
}

.legend-line-report .legend-item-line p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.activity-wraps {
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-top: 24px;
    padding: 24px;
    width: 100%;
}

.heading-activities-page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.heading-activities-page .title-activi {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #334D6E;
    width: calc(100% - 300px);
}

.buton-activi-wraps button {
    background: #016288;
    padding: 8px 14px;
    border-radius: 6px;
    border: 1px solid #016288;
    margin-right: 16px;
    color: #FFF;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
}

.buton-activi-wraps button:last-child {
    margin-right: 0;
}

.buton-activi-wraps button span {
    line-height: 0;
    font-size: 12px;
    margin-right: 8px;
}

.card-wraps-chart {
    width: 100%;
    max-width: 556px;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.card-wraps-chart .title-cards {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-right: 48px;
}

.card-wraps-chart .chart-storage-area {
    padding: 19px 22px 19px;
}

.mb0 {
    margin-bottom: 0 !important;
}

/* reports over */
.react-tel-input .special-label {
    display: none;
}

.react-tel-input .phone-input {
    padding: 9px 16px 9px 58px;
    width: 100%;
}

/* groups over */

@media screen and (max-width:767px) {
    .activity-wraps{
        padding: 15px;
    }
}

@media screen and (max-width:576px) {
    .heading-activities-page{
        flex-direction: column;
        align-items: flex-start;
    }
    .heading-activities-page .title-activi{
        width: 100%;
        margin-bottom: 15px;
    }
    .heading-activities-page .buton-activi-wraps{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .buton-activi-wraps button{
        padding: 8px 10px;
        text-align: center;
    }
}