.notification-section {
    padding: 0px 81px auto 30px;
}

.mark-read {
    background: transparent;
    border: 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(51, 77, 110, 1);
}

.notification-list-wrap li {
    list-style: none;
    margin-left: 0;
    margin-bottom: 15px;
    cursor: pointer;
}

.notification-time {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(194, 207, 224, 1);
    margin-bottom: 6px;
}

.notifications-wrap {
    
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 105px);
}

.notification-container{
    padding: 0px 30px;
}
.new{
    background:rgba(240, 241, 245, 1);
}
.notification-list-wrap {
    padding-left: 0px;
    margin-bottom: 0px;
}

.notification-list-wrap .notification-list {
    padding: 16px 24px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border: 1px solid rgba(245, 246, 248, 1);
    box-shadow: 2px 13px 28px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notification-list-wrap .notification-div-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 75px);
}

.notification-div-wrap .n-img-wrap {
    width: 48px;
    height: 48px;
    margin-right: 8px;
}

.notification-div-wrap .n-img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.mb {
    margin-bottom: 2px;
}

.n-text-wrap {
    margin-right: auto;
}

.n-user-name {
    font-family: Helvetica Neue;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
}

.n-user-todo {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: left;
}

.n-file-name {
    font-family: Helvetica Neue;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    color: var(--orange);
}

.n-comment {
    font-family: Helvetica Neue;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(51, 77, 110, 1);
}

.n-user-activity-time {
    font-family: Helvetica Neue;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(158, 168, 182, 1);

}

.delete-btn {
    background: transparent;
    border: 0px;
}
.pdf-file-wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
 
}
.n-uploadfile-data-wrap{
  padding: 7px;
}
.notification-list-wrap .noti-handle-btns{
    width: 100%;
    max-width: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.notification-list-wrap .noti-handle-btns .read_unred_icon{
    width: 18px;
    height: 18px;
    border-radius: 50px;
    border: 2px solid #016288;
    margin-right: 10px;
    cursor: pointer;
    background: transparent;
}
.notification-list-wrap .noti-handle-btns .read_unred_icon.is_active{
    background: #FCAF18;
    border-color: #FCAF18;
}
.n-uploadfile-wrap {
    padding: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background:rgba(240, 241, 245, 1);
    border-radius: 12px;
    display: inline-block;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.n-uploadfile-wrap .upload-file-li{
    margin-right: 8px;
    display: inline-block;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    box-shadow: 0px;
}

.n-pdf-name {
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 3px;
    color: rgba(51, 77, 110, 1);
}

.n-pdf-size {
    font-family: Helvetica; 
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 3px;
    color: rgba(158, 168, 182, 1);

}

@media screen and (max-width:570px){
    .notification-list-wrap .notification-list{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .notification-list-wrap .notification-div-wrap{
        width: 100%;
    }
    .notification-list-wrap .noti-handle-btns{
        justify-content: flex-end;
        margin-top: 15px;
        max-width: 100%;
    }
    .notification-container{
        padding: 0 47px 0 30px;
    }
}