.chat-section-page {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#notification .noti {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 1rem;
    height: calc(100vh - 10rem);
    overflow: auto;
    box-shadow: 0 0 5px -2px #ccc;
}

#notification .noti .noti-items {
    position: relative;
    padding: 1rem;
}

#notification .noti .noti-items .noti-body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.default-avatar {
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #ff7272;
    color: #fff;
}

#notification .noti .noti-items .noti-body__text {
    -webkit-flex: 1 1;
    flex: 1 1;
    margin-left: 2rem;
    width: 100%;
}

#notification .noti .noti-items .noti-body__text div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
}

#notification .noti .noti-items .noti-body__text div:first-child {
    font-weight: 400;
}

#notification .noti .noti-items .noti-body__text div:last-child {
    color: #ccc;
}

.hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

/* new css */


.chat-btn {
    color: white;
    background: rgba(1, 98, 136, 1);
    font-size: 12px;
    font-weight: 700;
    padding: 17.5px 12px;
    border-radius: 6px;
    border: 0px;
    margin-right: 8px;
}

.chat-btn .plus-icon {
    font-size: 12px;
    font-weight: 700;
}

.chats-left-wrap {
    padding: 38px 7px 38px 30px;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    max-width: 315px;
}

.chats-left-wrap .message {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin: 32px auto;
}

.chats-left-wrap .message span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FCAF18;
}

#notification .noti {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 1rem;
    height: calc(100vh - 10rem);
    overflow: auto;
    box-shadow: 0 0 5px -2px #ccc;
}

#notification .noti .noti-items {
    position: relative;
    padding: 1rem;
}

#notification .noti .noti-items .noti-body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.default-avatar {
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #ff7272;
    color: #fff;
}

#notification .noti .noti-items .noti-body__text {
    -webkit-flex: 1 1;
    flex: 1 1;
    margin-left: 2rem;
    width: 100%;
}

#notification .noti .noti-items .noti-body__text div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
}

#notification .noti .noti-items .noti-body__text div:first-child {
    font-weight: 400;
}

#notification .noti .noti-items .noti-body__text div:last-child {
    color: #ccc;
}

.hr {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

/* new css */


.chat-btn {
    color: white;
    background: rgba(1, 98, 136, 1);
    font-size: 12px;
    font-weight: 700;
    padding: 17.5px 12px;
    border-radius: 6px;
    border: 0px;
    margin-right: 0;
}

.chat-btn .plus-icon {
    font-size: 12px;
    font-weight: 700;
}

.left-wrap {
    padding: 38px 30px;
}

.left-wrap .message {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin: 32px auto;

}

.left-wrap .message span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FCAF18;
}

.chats-left-wrap .chat-input {
    background: url('../images//search-icon.svg') no-repeat 9px 50%;
    padding: 10px 10px 10px 32px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #C2CFE0;
    border: 0px;
    box-shadow: 2px 13px 12px 0px #0000000D;
    margin-bottom: 18px;
    width: 100%;
}

.left-wrap .chat-input:focus {
    border: 0px;
}

.chat-users-list {
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #F5F6F8;
    border-radius: 10px;
    width: 100%;
    height: calc(100vh - 275px);
    overflow: auto;
}

.chats-right-wrap {
    padding: 32px 15px 32px 0;
    width: calc(100% - 325px);
}

.chats-div.chats-page-area {
    width: 100%;
    height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: auto;
}

.chat-noti {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid #F5F6F8;
    border-radius: 12px;
    box-shadow: 2px 13px 28px 0px #0000000A;
    margin-bottom: 8px;
    border: 1px solid #F5F6F8;
}

.chat-img-wrap {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 14px;
    /* overflow: hidden; */
    position: relative;
}

.chat-img-wrap .img-werp {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 14px;
    overflow: hidden;
    position: relative;
}

.chat-img-wrap .img-werp img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    display: block;
}

.chat-img-wrap .img-werp .user-name-text {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #F5F6F8;
    font-size: 12px;
    color: #016288;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-img-wrap::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: 3;
    border-radius: 50%;
    transform: translate(254%, -77%);
    -webkit-transform: translate(254%, -77%);
    -moz-transform: translate(254%, -77%);
    -ms-transform: translate(254%, -77%);
    -o-transform: translate(254%, -77%);
    background: #016288;
    border: 1.5px solid #FFFFFF;
}

.user-name-out {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.chat-user-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 2px;
    color: #334D6E;
}


.chat-user-status {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 0px;

}

.chat-user-name.hide-text,
.chat-user-status.hide-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 10.5vw;
}

.chat-user-message {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 0px;
}

.chat-li-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 12px;
}

.chat-last-active {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
    margin-bottom: 12px;
    color: #9EA8B6;
}

.chat-li-wrap .diflex {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
}

.message-count {
    background: #FCAF18;
    padding: 4px 8px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin-left: auto;
    margin-right: 0px;
    color: #334D6E;
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
}

.chat-li-wrap.active {
    background: #F0F1F5;
    box-shadow: 2px 13px 15px 0px #0000000A;
}

.chat-container {
    border: 1px solid #F5F6F8;
    box-shadow: 2px 13px 28px 0px #0000000A;
    border-radius: 12px;
    height: 100%;
    /* padding: 12px; */
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.chat-wraps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.chat-wraps {
    height: 100%;
    width: 100%;
}

.input-chat-page-module {
    width: 84%;
    display: inline-block;
    position: relative;
    margin: 0 28px;
}

.chat-right-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.chat-right-wrap .main-right-chat-wrp {
    padding-left: 10vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.send-message-text {
    display: inline-block;
    position: relative;
    background: #F5F6F8;
    border-radius: 12px 12px 0px 12px;
    color: #016288;
    padding: 6px 12px;
    margin: 5px 0;
    margin-right: 12px;
    -webkit-border-radius: 12px 12px 0px 12px;
    -moz-border-radius: 12px 12px 0px 12px;
    -ms-border-radius: 12px 12px 0px 12px;
    -o-border-radius: 12px 12px 0px 12px;
}

.send-message-text .recieve-message-files {
    background: transparent;
    padding: 0;
    border-radius: 0;
    border: 0;
}

.send-message-text .recieve-message-files .recieve-file {
    color: #016288;
}

.send-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: right;
    margin-bottom: 0px;

}



.recieve-message-text {
    display: inline-block;
    position: relative;
    background: #016288;
    border-radius: 0px 12px 12px 12px;
    color: #FFF;
    padding: 6px 12px;
    margin-right: 12px;
    -webkit-border-radius: 0px 12px 12px 12px;
    -moz-border-radius: 0px 12px 12px 12px;
    -ms-border-radius: 0px 12px 12px 12px;
    -o-border-radius: 0px 12px 12px 12px;
}

.recieve-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: right;
    margin-bottom: 0px;

}

.chat-left-page-wrap .main-right-chat-wrp {
    padding-right: 10vw;
}

.text-chat-wrap {
    display: flex;
    justify-content: flex-start;

}

.recieve-message-files {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #016288;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    padding: 12px 22px;
    border: 1px solid #C2CFE0;
}

.file-chat-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    padding: 12px 32px;
    /* display: inline-block; */
    margin-right: 10px;
    border: 1px solid #C2CFE0;
}

.file-chat-wrap:last-child {
    margin-right: 0;
}

.recieve-pdf-wrap {
    display: inline-block;
    margin-right: 15px;
}

.recieve-pdf-wrap img {
    height: 35px;
}

.recieve-file {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #F5F6F8;

}

.r-file-size {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #9EA8B6;
    margin-bottom: 0px;
}

.chat-left-page-wrap {
    display: flex;
    align-items: flex-start;
}

.page-chat.chat-drop {
    position: absolute;

}

.text-chat-wrap .message-chat-wrap {
    position: relative;
    margin-bottom: 5px;
}

.chat-left-page-wrap .text-chat-wrap .message-chat-wrap .chat-drop {
    right: -25px;
    z-index: 1;
}

.chat-right-wrap .text-chat-wra .message-chat-wrap {
    position: relative;
}

.chat-right-wrap .text-chat-wra .message-chat-wrap .chat-drop {
    left: 0;
}

.blank-chat-wrap {
    width: 100%;
    height: calc(100vh - 75px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-sizing: border-box;
    border-radius: 12px;
}

.blank-chat-wrap h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #334D6E;
    margin: 40px 0 4px;
}

.blank-chat-wrap p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #334D6E;
    margin-bottom: 16px;
}
.chat-back-mobile{
    background: transparent;
    border: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

@media screen and (max-width:1023px) {
    .chat-section-page{
        align-items: center;
        justify-content: center;
    }
    .chats-left-wrap{
        max-width: 350px;
        
    }
}

@media screen and (max-width:767px) {
    .chats-right-wrap{
        width: 100%;
        padding: 32px 15px 32px 15px;
    }
    .chats-left-wrap{
        max-width: 100%;
        padding: 38px 15px 38px 15px;
    }
    .input-chat-page-module{
        width: 65%;
        margin: 0 10px;
    }
}