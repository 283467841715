.right-side-section {
    width: 100%;
    height: 100vh;
    max-width: 300px;
    display: inline-block;
    background: #F5F6F8;
    overflow: hidden;
    overflow-y: auto;
}

.right-side-section.is-mobile {
    position: fixed;
    top: 65px;
    right: -300px;
    transition: right 0.3s linear;
    z-index: 10;
}

.right-side-section.is-mobile.is_open {
    right: 0;
}

.home-page-section {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
}

.home-page-section.is-mobile {
    height: calc(100vh - 65px);
}

.hr-verticle {
    width: 1px;
    height: 100vh !important;
    display: inline-block;
    opacity: 1;
    margin: 0;
    background: #C2CFE0;
}

.home-activities-container {
    background-color: #FFF;
    padding: 24px 29px 0 23px;
    display: inline-block;
    width: 100%;
}

.topic-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.topic-hadding {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.list-views {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 8px;
}

.sub-topic-title {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #C2CFE0;
    margin-bottom: 19px;
}

.viewlist-activities {
    width: 100%;
    /* height: 100%; */
    height: 89vh;
    padding-left: 0;
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    list-style: none;
    /* overflow: hidden;
    overflow-y: auto; */
}

.viewlist-activities li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    width: 100%;
    margin-bottom: 16px;
}

.viewlist-activities li:last-child {
    margin-bottom: 0;
}

.viewlist-activities li .image-wraps {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    margin-right: 12px;
}

.viewlist-activities li .image-wraps img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.viewlist-activities li .content-wraps {
    width: calc(100% - 44px);
    display: flex;
    flex-direction: column;
    position: relative;
}

.viewlist-activities li .content-wraps p {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
    width: 100%;
    display: inline-block;
}

.viewlist-activities li .content-wraps span {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #9EA8B6;
}

.page-top-header {
    padding: 31px 24px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-title {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
}

.form-search-wrap {
    width: 100%;
    min-width: 330px;
    display: inline-block;
    position: relative;
}

.search-input {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 12px 10px 12px 36px;
    background: #F5F6F8;
    border: 0;
    border-radius: 10px;
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #016288;
}

.search-input:focus {
    outline: 0;
    box-shadow: none;
}

.search-input::placeholder {
    font-family: "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #016288;
}

.w-66 {
    width: 66%;
}

.search-btn {
    padding: 0;
    background: transparent;
    border: 0;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: 0;
    border-radius: 10px;
    background: #F5F6F8;
    margin: 0 40px 0 16px;
}

.btn-uploads {
    background-color: #FCAF18 !important;
    /* background: #016288; */
    border-radius: 6px;
    padding: 17.5px 26.16px !important;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border: 0;
}

.btn-uploads svg {
    margin-right: 8px;
}

.btn-uploads::after {
    display: none;
}

.custom-tab {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #C2CFE0;
    padding-left: 24px;
}

.custom-tab.custom-tab2 {
    /* margin-left: -24px;
    margin-right: -24px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.custom-tab li .nav-link,
.custom-tab.custom-tab2 .nav-link {
    padding: 20px 18px;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #334D6E;
    position: relative;
    /* display: inline-block; */
    border: 1px solid transparent;
    background: transparent;
}

.custom-tab.custom-tab2 .nav-link {
    top: 1px;
}

.custom-tab li .nav-link:hover,
.custom-tab.custom-tab2 .nav-link:hover {
    border-radius: 12px 12px 0px 0px;
    border-color: transparent;
}

.custom-tab li .nav-link.active,
.custom-tab.custom-tab2 .nav-link.is-active {
    background: linear-gradient(180deg, #F5F6F8 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 12px 12px 0px 0px;
    font-weight: 700;
    border: 1px solid #C2CFE0;
    border-bottom-color: #FFF;
}

.custom-tab li .nav-link .notify-icons,
.custom-tab.custom-tab2 .nav-link .notify-icons {
    padding: 8px 12px 7px;
    background: #FCAF18;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -12px;
    right: -8px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    z-index: 2;
}

.custom-tab li .nav-link:hover,
.custom-tab.custom-tab2 .nav-link:hover {
    isolation: unset;
}

.custom-tab li .nav-link:hover .notify-icons,
.custom-tab.custom-tab2 .nav-link:hover .notify-icons {
    z-index: 999;
}

.tab-panel-custom {
    padding: 38px 24px 38px 25px;
}

.chat-home-container {
    width: 100%;
    display: inline-block;
    position: relative;
    border: 1px solid #E5E8EF;
    border-left: 0;
}

.search-chat-wraps {
    width: 100%;
    padding: 18px 28px 15px 20px;
}

.search-chat-wraps .label-search {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 16px;
}

.chat-wraps {
    width: 100%;
    background: #FFF;
}

.chat-user-profile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 28px 20px 20px;
}

.chat-user-profile .user-info {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
}

.chat-user-profile .user-info .user-profile {
    position: relative;
    margin-right: 14px;
}

.chat-user-profile .user-info .user-profile .user-image {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.chat-user-profile .user-info .user-profile .user-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat-user-profile .user-info .user-profile .login-notifi {
    position: absolute;
    bottom: 9px;
    right: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #048f10;
    border: 1.5px solid #FFF;
}

.chat-user-profile .user-info .info-text {
    display: inline-block;
    position: relative;
}

.chat-user-profile .user-info .info-text p {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #0D1C2E;
    margin-bottom: 8px;
}

.chat-user-profile .user-info .info-text span {
    display: block;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #2A8BF2;
}

.storage-pinbar-wraps {
    width: 100%;
    display: inline-block;
    position: relative;
}

.plan-up-groups {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 35px 0 24px;
    padding: 0 7px;
}

.plan-up-groups .plan-text {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #C2CFE0;
    max-width: 119px;
    margin-bottom: 0;
}

.upload-file-view {
    width: 100%;
    display: inline-block;
    position: relative;
    list-style: none;
    padding-left: 0;
    margin-bottom: 22px;
}

.upload-file-view li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.upload-file-view li:last-child {
    margin-bottom: 0;
}

.upload-file-view li .file-part {
    width: 100%;
    max-width: 80%;
    display: flex;
    align-items: center;
}

.upload-file-view li .file-part .file-icon-wra {
    width: 34px;
    height: 34px;
    background: #F5F6F8;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    margin-right: 10px;
}

.upload-file-view li .file-part .file-icon-wra img {
    width: 100%;
}

.upload-file-view li .file-part .text-file-names {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.upload-file-view li .file-part .text-file-names span {
    display: block;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #9EA8B6;
    margin-bottom: 4px;
}

.upload-file-view li .file-size-text {
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #016288;
    margin-bottom: 0;
    text-align: right;
}

.file-upload-section {
    width: 100%;
    display: inline-block;
    position: relative;
}

@media screen and (max-width:1023px) {
    .btn-uploads svg {
        margin-right: 0;
    }
}
.is_mobile_tab{
    display: none !important;
}
@media screen and (max-width:767px) {
    .custom-tab.custom-tab2.is-desktop{
        display: none;
    }
    .is_mobile_tab{
        display: flex !important;
    }
    .custom-tab{
        padding: 0 24px;
    }
}

@media screen and (max-width:570px) {
    .page-top-header .form-search-wrap {
        min-width: 250px;
    }
    .page-top-header .page-title{
        width: 100%;
        font-size: 21px;
    }
}

@media screen and (max-width:320px) {
    .page-top-header .page-title{
        width: 100%;
        font-size: 21px;
    }
    .page-top-header .form-search-wrap{
        min-width: 100px;
        max-width: 100px;
        /* flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; */
    }
    .page-top-header .form-search-wrap.w-100{
        max-width: 100%;
    }
}