.project-details-section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.left-wrap-projectd {
    width: calc(100% - 354px);
}

.left-wrap-projectd .scroll-wrpas {
    width: 100%;
    height: calc(100vh - 135px);

}

.left-wrap-projectd .scroll-wrpas .scroll-wraps-padding {
    padding: 0px 24px 24px 30px;
}

.heading-page-title-wrap {
    width: 100%;
    display: inline-block;
    position: relative;
    /* margin-bottom: 24px; */
    padding: 38px 24px 24px 30px;
}

.heading-page-title-wrap h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 16px;
}

.heading-page-title-wrap button {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #016288;
}

.heading-page-title-wrap button span {
    color: #C2CFE0;
    font-size: 22px;
    margin-right: 8px;
    line-height: 0;
}

.details-card {
    background: #FFFFFF;
    padding: 24px;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin: 0px 0 18px;
}

.details-card .header-details {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 16px;
}

.details-card .header-details .img-with-text-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.details-card .header-details .img-with-text-wrap .img-wraps {
    width: 64px;
    height: 64px;
    background: #F5F6F8;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-right: 12px;
    overflow: hidden;
}

.details-card .header-details .img-with-text-wrap .img-wraps img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.details-card .header-details .img-with-text-wrap .img-wraps .p-name {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #016288;
    width: 100%;
    height: 100%;
    /* background: #FFF; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.details-card .header-details .img-with-text-wrap h2 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.details-card .header-details .button-wraps {
    position: absolute;
    right: 0;
    top: 0;
}

.details-card .header-details .button-wraps button {
    background: transparent;
    border: 0;
    cursor: pointer;
    margin-right: 10px;
}

.details-card .header-details .button-wraps button:last-child {
    margin-right: 0;
}

.details-card .deadline-wraps-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin: 16px 0 8px;
}

.details-card .deadline-wraps-block .deadline-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.details-card .deadline-wraps-block .deadline-text span {
    background: #FCAF18;
    border-radius: 6px;
    padding: 3px 8px 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-left: 8px;
    text-align: center;
}

.details-card .deadline-wraps-block .deadline-date {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.details-card .deadline-wraps-block .deadline-date span {
    margin-right: 8px;
    font-size: 18px;
    color: #C2CFE0;
    line-height: 0;
}

.details-card .descriptin-wrap {
    width: 100%;
    display: inline-block;
    position: relative;
}

.details-card .descriptin-wrap .title-description {
    padding: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.details-card .descriptin-wrap .text-details {
    width: 100%;
    padding: 8px;
    display: inline-block;
    position: relative;
    border: 1px solid #F5F6F8;
    box-sizing: border-box;
    border-radius: 8px;
}

.details-card .descriptin-wrap .text-details p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.9;
    margin-bottom: 0;
}

.card-both-wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.card-both-wrap .card-same-wrap {
    width: 100%;
    max-width: 393px;
    max-height: 414px;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-sizing: border-box;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 24px;
    margin: 0px 8px;
    min-height: 413px;
}

.card-both-wrap .card-same-wrap:first-child {
    margin-left: 0;
}

.card-both-wrap .card-same-wrap:last-child {
    margin-right: 0;
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo .button-groups-todo {
    width: calc(100% - 200px);
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo .button-groups-todo button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: transparent;
    border: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.5;
    margin-bottom: 8px;
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo .button-groups-todo button.is_active {
    opacity: 1;
    font-size: 18px;
    font-weight: bold;
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo .button-groups-todo button:last-child {
    margin-bottom: 0;
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo .button-groups-todo button .icon {
    line-height: 0;
    margin-right: 7px;
    font-size: 11px;
    color: #FFF;
    background: #FCAF18;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo .button-groups-todo button.is_active .icon {
    display: none;
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo .button-groups-todo button .number {
    margin-left: 8px;
}

.card-both-wrap .card-same-wrap.todo-wraps .head-wraps-todo .button-groups-todo button.is_active .number {
    opacity: 0.5;
}

.create-btn {
    padding: 15px 14px 16px;
    background: #016288;
    border-radius: 6px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
}

.create-btn .icon {
    margin-right: 8px;
    line-height: 0;
}

.card-same-wrap .todo-list {
    padding-left: 0;
    margin: 32px 0 0;
    list-style: none;
}

.card-same-wrap .todo-list li {
    list-style: none;
    display: block;
    margin-bottom: 16px;
}

.card-same-wrap .todo-list li:last-child {
    margin-bottom: 0;
}

.card-same-wrap .todo-list li .todo-task-grid {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px 24px;
    position: relative;
    cursor: pointer;
}

.card-same-wrap .todo-list li .todo-task-grid h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 8px;
    padding-right: 4.5rem;
}

.card-same-wrap .todo-list li .todo-task-grid p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.65;
    margin-bottom: 0;
}

.card-same-wrap .todo-list li .todo-task-grid .groups-of-btns-todo {
    position: absolute;
    top: 16px;
    right: 24px;
    display: none;
}

.card-same-wrap .todo-list li .todo-task-grid .groups-of-btns-todo .bton {
    background: 0;
    padding: 0;
    border: 0;
    margin-right: 10px;
}

.card-same-wrap .todo-list li .todo-task-grid .groups-of-btns-todo button:last-child {
    margin-right: 0;
}

.card-same-wrap .todo-list li .todo-task-grid .groups-of-btns-todo button .big-icon-small {
    width: 16px;
    height: 16px;
}

.card-same-wrap .todo-list li .todo-task-grid.is_selected {
    border: 1px solid #016288;
    box-sizing: border-box;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.card-same-wrap .todo-list li .todo-task-grid.is_selected .groups-of-btns-todo,
.card-same-wrap .todo-list li .todo-task-grid.done .groups-of-btns-todo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-same-wrap .todo-list li .todo-task-grid.done .groups-of-btns-todo .delete,
.card-same-wrap .todo-list li .todo-task-grid.done .groups-of-btns-todo .edit {
    display: none;
}

.card-same-wrap .todo-list li .todo-task-grid.done .groups-of-btns-todo .done svg path {
    stroke: #FCAF18;
}

.card-same-wrap.recent-wraps .head-wraps-recent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.card-same-wrap.recent-wraps .head-wraps-recent h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.card-same-wrap.recent-wraps .head-wraps-recent h3 .number {
    opacity: 0.5;
}

.card-same-wrap.recent-wraps .head-wraps-recent .create-btn.view-icon-btns {
    width: 100%;
    max-width: 139px;
}

/* right-sidebar css */

.right-sidebar-details {
    width: 100%;
    max-width: 354px;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    border-left: 1px solid #E5E8EF;
}

.overview-wraps {
    width: 100%;
    padding: 24px 24px 32px;
    display: inline-block;
    position: relative;
    border-bottom: 1px solid #E5E8EF;
}

.overview-wraps .title-over {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 50px;
}

/* progress bar css */

.progress_overview {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* box-shadow: inset 0 0 50px #000;
    background: #222; */
    z-index: 1000;
    margin: 0 auto 30px;
}

.progress_overview svg {
    position: relative;
    width: 160px;
    height: 160px;
    z-index: 1000;
}

.progress_overview svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #FCAF18;
    stroke-width: 17;
    stroke-linecap: round;
    transform: rotate(90deg);
    /* transform: translate(5px, 5px); */
}

.progress_overview svg circle:nth-child(2) {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

.progress_overview svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 50) / 100);
    stroke: #016288;
}

/* progress bar css over */

.overview-wraps .text-overview {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #016288;
    margin: 4px 0 24px;
}

.overview-wraps .hours-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    padding-bottom: 6px;
    position: relative;
    margin-bottom: 0;
}

.overview-wraps .hours-title::after {
    content: '';
    width: 64px;
    height: 3px;
    display: inline-block;
    background: #FCAF18;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 40%;
    transform: translate(0, -50%);
}

.overview-wraps .dead-line-block {
    width: 100%;
    max-width: 305;
    margin: 24px auto 0;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.overview-wraps .dead-line-block p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #016288;
    text-align: center;
}

.overview-wraps .dead-line-block p span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.5;
    margin-bottom: 8px;
}

.team-member-wrap {
    width: 100%;
    padding: 24px 16px 24px 24px;
}

.team-member-wrap .head-members-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.team-member-wrap .head-members-wrap p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.team-member-wrap .head-members-wrap p span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: block;
    letter-spacing: 0.01em;
    color: #C2CFE0;
    margin-top: 8px;
}

.team-member-wrap .head-members-wrap button {
    width: 100%;
    max-width: 123px;
}

.team-member-wrap .member-list-ul {
    margin: 32px 0 0;
    padding-left: 0;
    list-style: none;
    width: 100%;
}

.team-member-wrap .member-list-ul li {
    list-style: none;
    width: 100%;
    max-width: 267px;
    margin-bottom: 16px;
}

.team-member-wrap .member-list-ul li:last-child {
    margin-bottom: 0;
}

.team-member-wrap .member-list-ul li .member-wraps {
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.team-member-wrap .member-list-ul li .member-wraps .member-image-wrap {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 19px;
    border: 2px solid transparent;
}

.team-member-wrap .member-list-ul li .member-wraps .member-image-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-member-wrap .member-list-ul li .member-wraps .member-image-wrap .m-name {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #016288;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F8;
}

.team-member-wrap .member-list-ul li .member-wraps p {
    width: calc(100% - 67px);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0px;
    padding-bottom: 20px;
    border-bottom: 1px solid #C2CFE0;
}

.team-member-wrap .member-list-ul li .member-wraps p span {
    display: block;
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #334D6E;
    opacity: 0.65;
}

.team-member-wrap .member-list-ul li .member-wraps.is_active .member-image-wrap {
    border-color: #016288;
}

.team-member-wrap .member-list-ul li .member-wraps.is_delete .member-image-wrap {
    filter: brightness(0.5);
}

.team-member-wrap .member-list-ul li .member-wraps.is_active p {
    border-color: #016288;
}

.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    display: none;
}

.team-member-wrap .member-list-ul li .member-wraps.is_active .group-of-buttons {
    display: flex;
}

.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons .delete,
.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons .report {
    background: transparent;
    border: 0;
}

.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons button.delete svg {
    width: 16px;
    height: 16px;
}

.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons button.report {
    font-size: 15px;
    color: #9EA8B6;
}

.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons button:hover svg path {
    stroke: #016288;
}

.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons button.report:hover {
    color: #016288;
}

.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons button {
    margin-right: 5px;
}

.team-member-wrap .member-list-ul li .member-wraps .group-of-buttons button:last-child {
    margin-right: 0;
}


/* right-sidebar css over */

@media screen and (max-width:767px) {
    .project-details-section {
        flex-direction: column;
    }
    .heading-page-title-wrap{
        padding: 38px 15px 24px 15px;
    }
    .left-wrap-projectd {
        width: 100%;
    }
    .left-wrap-projectd .scroll-wrpas .scroll-wraps-padding{
        padding: 0px 15px 24px 15px;
    }
}