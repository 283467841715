.signin-section {
    background: url('../images/signin/Background.png')no-repeat center bottom;
    background-size: 100%;
    padding-top: 10px;
}

.signin-page {
    max-width: 1117px;
    padding-bottom: 50px;
    width: 100%;
}

.signin-page .logo-wrap {
    width: 220px;
    margin-bottom: 25px;
}

.signin-page .logo-wrap img {
    width: 100%;
}

.title, text {
    color: var(--grey-01);
    margin-bottom: 14px;
}

.title {
    font-size: 32px;
    font-weight: 500;
}

.text {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
}

.form-wrap {
    max-width: 555px;
    display: block;
    background: #FFF;
    padding: 35px 34px 54px;
    /* padding-top: 46px; */
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    position: relative;
    top: -70px;
    box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.16), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

}

.title-wrap {
    display: flex;
    margin-bottom: 16px;
}

.prev-btn {
    border: 0px;
    background: #FFF;
    display: inline-block;
}

.form-title {
    font-weight: 500;
    font-size: 40px;
    display: block;
    margin-bottom: 0px;

}

.email-wrap, .password-wrap {
    margin-top: 100px;
    margin-bottom: 16px;
}

.password-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

}

.password-email {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-email img {
    margin-right: 5px;
}

.password-wrap img {
    border: 0px;
    background: #FFF;
    display: inline-block;
    padding: 6px;

}

.password-wrap .icon-eye {
    display: inline-block;
    padding: 6px;
    color: #8a97a2;
    font-size: 18px;
    cursor: pointer;
}

.email-wrap input, .password-wrap input {
    border: none;
    font-size: 16px;
    color: var(--grey-02);
    width: 100%;
    padding: 12px 0;
}

.email-wrap input {
    border-bottom: 0.5px solid rgba(77, 89, 100, 0.5);
}

.password-wrap {
    margin-bottom: 13px;
    border-bottom: 0.5px solid rgba(77, 89, 100, 0.5);
}

.password-wrap:hover {
    border-bottom: 0.5px solid turquoise;
}

.email-wrap input:hover, .email-wrap input:focus, .password-wrap input:focus, .password-wrap input:hover, .password-wrap img:hover, .password-wrap img:focus {
    outline: none;
    border: 0px;

}

.email-wrap input:hover, .email-wrap input:focus {
    border-bottom: 0.5px solid turquoise;
}


.ms-auto {
    margin-bottom: 112px;
    text-align: end;
    margin-right: 0;
    padding-right: 0;
}

.forgot-pass {
    font-size: 14px;
    font-weight: 400;
    color: #008CAA;
    display: inline-block;
    text-decoration: none;
}

.form-wrap .form-btn {
    width: 100%;
    max-width: 390px;
    height: 56px;
    display: block;
    background-color: #FCAF17;
    font-size: 18px;
    color: #FFF;
    border: 0px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin-bottom: 64px !important;
    margin: auto;
}

.form-wrap .signin-link {
    font-size: 14px;
    font-weight: 400px;
    text-decoration: none;
    color: var(--grey-02);
}

.other-signin-btns {
    display: flex;
    align-items: center;

}

.other-signin-btns .signin-btn {
    max-width: 149.24px;
    max-height: 47.03px;
    text-decoration: none;
    font-size: 16px;
    border: 0px;
    background: #FFFFFF;
    color: var(--grey-01);
    letter-spacing: 0.015em;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 12px 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.other-signin-btns .signin-btn img {
    width: 27.14px;
    /* height: 27.14px; */
    margin-left: 7px;
}

.captcha-wrap {
    height: 84px;
    width: 353px;
    display: block;
    margin: auto;
    margin-bottom: 42px !important;
}

.captcha {
    background-color: wheat;
}

.otp-text {
    margin-top: 123px;
    margin-bottom: 94px;
    font-size: 18px;
    color: var(--grey-02);
}

.otp-wrap {
    margin-bottom: 21px;
    text-align: center;

}

.otp input {
    display: block;
    border: 0px;
    padding: 0px;
    width: 28px !important;
    margin-right: 12px;
    border-bottom: 2px solid var(--grey-02);
}

.otp:first-child {
    margin-left: auto;

}

.otp:last-child {
    margin-right: auto;
}

.otp input:hover, .otp input:focus {
    outline: none;
    border: 0px;
    border-bottom: 2px solid var(--grey-02);
}

.otp-time-text {
    font-size: 14px;
    padding-bottom: 214px;
    color: var(--grey-02);
}

.otp-time-text .form-wrap {
    padding-bottom: 214px !important;
}


@media screen and (max-width:1024px) {

    .form-wrap {
        max-width: 470px;
    }

    .form-title {
        font-size: 27px;
    }

    .form-wrap .form-btn {
        margin-bottom: 35px;
    }

    .pc-img-wrap {
        display: block;
        margin: 0 auto;
        max-width: 350px;
    }

    .pc-img-wrap img {
        width: 100%;
    }



    .other-signin-btns .signin-btn {
        font-size: 13px;
    }
}

@media screen and (max-width:768px) {
    .signin-section {
        background: none;
    }

    /* .signin-page .logo-wrap {
        margin: 10px auto;
    } */

    .title {
        font-size: 15px;
        text-align: left;
    }

    .text {
        font-size: 12px;
        text-align: left;
    }

    .pc-img-wrap {
        display: block;
        margin: 0 auto;
        text-align: center;
        max-width: 300px;

    }

    .pc-img-wrap img {
        display: inline-block;
        width: 100%;
    }

    .form-wrap {
        max-width: 400px;
    }

    .form-title {
        font-size: 25px;
    }

    .form-btn {
        max-width: 280px !important;
        margin-bottom: 30px;
    }

    .other-signin-btns {
        display: flex;
        flex-direction: column;
        align-items: center;


    }

    .other-signin-btns .signin-btn {
        margin-bottom: 10px !important;
        margin: 0px;
    }

    .signin-btn {
        font-size: 10px !important;
    }

    .other-signin-btns img {
        max-width: 20px;
        max-height: 20px;
    }

    .password-wrap {
        margin-top: 60px;
    }

    .ms-auto {
        margin-bottom: 60px;
    }

}

@media screen and (max-width:767px) {
    .title {
        font-size: 15px;
        text-align: center;
    }

    .text {
        font-size: 12px;
        text-align: center;
    }
    .signin-section .container{
        max-width: 600px;
    }
    .form-wrap{
        padding: 35px 24px 54px;
    }
}


@media screen and (max-width:425px) {
    .signin-section {
        background: none;
    }

    .signin-page .logo-wrap {
        margin: 20px auto;
    }

    .pc-img-wrap {
        max-width: 240px !important;
    }

    .pc-img-wrap img {
        width: 100%;
    }

    .title {
        font-size: 15px;
        text-align: center;
    }

    .text {
        font-size: 12px;
        text-align: center;
    }

    .form-wrap {
        text-align: center;
    }

    .form-title {
        font-size: 30px;
    }

    .form-btn {
        max-width: 268px !important;
    }

    .signin-btn {
        font-size: 10px !important;
    }

    .other-signin-btns img {
        max-width: 20px;
        max-height: 20px;
    }

    .consent-container {
        flex-direction: column;
    }

    .consent-container .consent-content,
    .consent-container .consent-buttons {
        width: 100%;
    }

    .consent-container .consent-buttons {
        flex-direction: row;
    }

}

@media screen and (max-width:3750px) {
    .captcha-wrap {
        transform: scale(0.88);
        transform-origin: 0 0;
    }
}

@media screen and (max-width:320px) {
    .captcha-wrap {
        transform: scale(0.77);
        transform-origin: 0 0;
    }
}