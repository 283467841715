@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Medium.eot');
    src: url('../fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Medium.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Medium.woff') format('woff');
        /* url('../fonts/HelveticaNeue-Medium.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg'); */
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Thin.eot');
    src: url('../fonts/HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Thin.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Thin.woff') format('woff');
        /* url('../fonts/HelveticaNeue-Thin.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Thin.svg#HelveticaNeue-Thin') format('svg'); */
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Bold.eot');
    src: url('../fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Bold.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Bold.woff') format('woff');
        /* url('../fonts/HelveticaNeue-Bold.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg'); */
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Light.eot');
    src: url('../fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Light.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Light.woff') format('woff');
        /* url('../fonts/HelveticaNeue-Light.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg'); */
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue.eot');
    src: url('../fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue.woff2') format('woff2'),
        url('../fonts/HelveticaNeue.woff') format('woff');
        /* url('../fonts/HelveticaNeue.ttf') format('truetype'),
        url('../fonts/HelveticaNeue.svg#HelveticaNeue') format('svg'); */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeueRegular.eot');
    src: url('../fonts/HelveticaNeueRegular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueRegular.woff2') format('woff2'),
        url('../fonts/HelveticaNeueRegular.woff') format('woff');
        /* url('../fonts/HelveticaNeueRegular.ttf') format('truetype'),
        url('../fonts/HelveticaNeueRegular.svg#HelveticaNeueRegular') format('svg'); */
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeue-Italic.eot');
    src: url('../fonts/HelveticaNeue-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Italic.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Italic.woff') format('woff');
        /* url('../fonts/HelveticaNeue-Italic.ttf') format('truetype'),
        url('../fonts/HelveticaNeue-Italic.svg#HelveticaNeue-Italic') format('svg'); */
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

