.page-top-header.workflow-top-header {
    padding: 31px 24px 32px 18px;
}

.overview-wrap {
    padding: 16px 24px;
    margin: 0px 0px 40px 12px;
    box-shadow: 0px 6px 18px 0px #0000000F;
    border-radius: 12px;
}

.overview-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 30px;

}

.overview-list {
    display: flex;
    flex-wrap: wrap;
    column-count: 4;
    column-gap: 25px;
    row-gap: 25px;
    /* flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start; */
    padding-left: 0px;
    margin-bottom: 0px;
}

.overview-list .overview-badge {
    list-style: none;
    padding: 12px 24px;
    border-radius: 12px;
    background: #F5F6F8;
    /* margin-right: 12px;
    margin-left: 12px; */
    /* max-width: 170px; */
    max-width: 145px;
    width: 100%;
    /* box-shadow: 0px 6px 18px 0px #0000000F; */
}

/* .overview-list .overview-badge:first-child {
    margin-left: 0;
}

.overview-list .overview-badge:last-child {
    margin-right: 0;
} */

.overview-list-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
}

.overview-list-bar {
    width: 6px;
    height: 48px;
    background: #FCAF18;
    border-radius: 6px;
    margin-right: 8px;
    display: inline-block;
}

.overview-number {
    font-size: 48px;
    line-height: 48px;
    color: #016288;
    display: inline-block;
    padding: 0px;
}

/* .project-status-wraps {
    margin: 0px 0px 0px 12px;
    overflow: auto;
    height: calc(100vh - 300px);
} */

/* .project-status-wraps .main-box, */
.project-status-div {
    margin: 0px 0px 0px 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: auto;
    /* height: calc(100vh - 300px); */
}

.project-status-div .grid-view-drag {
    width: 100%;
    min-width: 225px;
    max-width: 254px;
    margin: 0 10px;
}

.project-status-div .grid-view-drag:first-child {
    margin-left: 0;
}

.project-status-div .grid-view-drag:last-child {
    margin-right: 0;
}

.project-status-badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    max-width: 254px;
    background: #F5F6F8;

}

.project-status-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-right: auto;
    color: #334D6E;
    margin-bottom: 0px;
}

.status-number {
    padding: 1px 6px;
    font-size: 14px;
    line-height: 17px;
    color: #FFF;
    background: #016288;
    border-radius: 2px;
    margin-bottom: 0px;
}

.project-status-div .grid-view-drag .list-project-drag {
    width: 100%;
    height: calc(100vh - 195px);
}

.progress-swiper {
    width: 100%;
    border-left: 1px solid #E5E8EF;
    border-bottom: 1px solid #E5E8EF;
}

.mySwiper .slider-wraps {
    padding: 24px;
}

.progress-main-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 0px;
    /* padding: 24px 0px; */
}

.progress-swiper .swiper-pagination-bullet {
    width: 5px !important;
    height: 5px !important;
    background: #334D6E !important;
    opacity: 0.2;
}

.progress-swiper .swiper-pagination-bullet-active {
    width: 20px !important;
    height: 5px !important;
    border-radius: 13.33px;
    background: #FCAF18 !important;
    opacity: 1;
}

.lagend-overview-chat {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lagend-overview-chat .langend-text-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100px;
    position: relative;
    margin-right: 10px;
}

.lagend-text-main {
    width: 100%;
    text-align: center;
    margin: 15px 0;
}

.lagend-text-main p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    margin-bottom: 0;
}

.lagend-overview-chat .langend-text-wrap:last-child {
    margin-right: 0;
}

.lagend-overview-chat .langend-text-wrap .shape-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

.lagend-overview-chat .langend-text-wrap p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #334D6E;
    text-align: left;
}

.lagend-overview-chat .langend-text-wrap p span {
    display: block;
    margin-top: 2px;
}

.drag-drop-container {
    padding: 0px 24px;
}

.drag-drop-btn {
    width: 254px;
    border: 0px;
    height: 49px;
    border-radius: 6px;
    padding: 12px 6px;
    border: 1.5px dashed #C2CFE0;
    background: #FFF;
    color: #016288;
    font-size: 12px;
    display: inline-block;
    font-weight: 700;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drag-drop-btn .plus-icon {
    color: #016288;
    line-height: 0px;
    font-size: 20px;
    margin-right: 8px;
}

.file-testing-wrap {
    padding: 12px;
    border: 1px solid #F5F6F8;
    border-radius: 8px;
    box-shadow: 2px 13px 28px 0px #0000000A;
    margin: 15px 0;
}

.testing-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin: 16px auto;
}

.test-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #FFF;
    /* padding: 8px; */
    overflow: hidden;
    /* box-shadow: 2px 13px 28px 0px #0000000A; */
}

.test-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.test-icon .p-name {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #016288;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F8;
}

.team-member-table-wrap {
    width: 100%;
    padding: 22px 24px;
    border-left: 1px solid #E5E8EF;
}

.member-table {
    width: 100%;
}

.member-table>thead>tr>th, .member-table>tbody>tr>td {
    /* height: 100%; */
    margin-bottom: 16px;
}

.member-table-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 17px;

}

.member-table thead>tr>th {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-align: left;
    color: #C2CFE0;
    padding-bottom: 8px;
    /* width: 100%;
    padding: 5px 45px 5px 0px; */
}

.member-table tbody>tr>td {
    /* width: 100%; */
    padding: 8px 0;
}

.member-table>tbody>tr>td .total-task, .member-table>tbody>tr>td .total-hours {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
}

.member-table .member-user-wrap {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: inline-block;
    object-fit: cover;
    margin-right: 12px;
    overflow: hidden;
}

.member-table .member-user-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.member-table .member-user-wrap .m-name {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #F5F6F8;
    font-size: 10px;
    color: #016288;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.member-table .member-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #334D6E;
    margin-bottom: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 55%;
}

.block-overview {
    padding-top: 50px;
}

.workflow-btn {
    border: 0px;
    /* max-width: 264px; */
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
    background: #016288;
    color: #FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 17.5px;
    display: block;
    margin: 40px auto 16px;
}

.workflow-btn.top {
    display: flex;
    align-items: center;
    justify-content: center;
}

.workflow-btn.top i {
    line-height: 0;
    margin-right: 8px;
    font-size: 20px;
}

/* create-project */
.create-project-section {
    padding: 38px 24px 38px 30px;
    /* width: 100%;
    height: (100vh - 10px);
    overflow: auto; */
}

.back-manu-handler {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
}

.back-manu-handler .icon-arrow-left {
    font-size: 25px;
    line-height: 0;
    color: #C2CFE0;
    cursor: pointer;
    margin-right: 8px;
}

.back-manu-handler .heading--active {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #016288;
    margin-bottom: 0;
}

.create-project-wraps {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.create-project-wraps .project-info,
.create-project-wraps .project-task {
    width: 100%;
    max-width: 563px;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #F5F6F8;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.create-project-wraps .project-info {
    margin-right: 24px;
}

.create-project-wraps .project-info .heading--active,
.create-project-wraps .project-task .heading--active {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

.create-project-wraps .project-info .heading--active {
    margin-bottom: 40px;
}

.create-project-wraps .project-task .heading--active {
    margin-bottom: 24px;
}

.project-info .project-thumbnail {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 32px;
}

.project-info .project-thumbnail .project-image {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    overflow: hidden;
}

.project-info .project-thumbnail .project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-info .project-thumbnail .project-image .p-name {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F6F8;
    font-size: 35px;
    color: #016288;
    line-height: 0;
}

.project-info .project-thumbnail .file-select-btn {
    background: #FCAF18;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0;
    position: absolute;
    bottom: 0;
    right: 0;
}

.project-info .project-thumbnail .file-select-btn .icon-camera {
    font-size: 18px;
    line-height: 0;
    color: #FFF;
}

.project-switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
}

.project-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.project-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.project-switch .slider.round {
    border-radius: 34px;
}

.project-switch .slider::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    transition: .4s;
}

.project-switch .slider.round::before {
    border-radius: 50%;
}

.project-switch input:checked+.slider {
    background-color: #016288;
}

.project-switch input:checked+.slider::before {
    transform: translateX(26px);
}

.task-menu-timer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.task-menu-timer .title {
    font-size: 18px;
    margin-bottom: 0;
}

.timer-wraps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
}

.timer-wraps .time-blocks {
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #E5E8EF;
    margin: 0 15px;
    border-radius: 5px;
}

.timer-wraps .time-blocks:first-child {
    margin-left: 0;
}

.timer-wraps .time-blocks:last-child {
    margin-right: 0;
}

.timer-wraps .title--bold {
    font-size: 16px;
    text-align: center;
}

.timer-wraps .time-text {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 500;
    color: #334D6E;
}

.list-of-report {
    width: 100%;
    margin-top: 25px;
}

.wrap-report-task {
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #E5E8EF;
}

.wrap-report-task:first-child {
    margin-top: 0;
}

.wrap-report-task .date-task {
    margin-bottom: 0;
    font-size: 15px;
    color: #334D6E;
}

.wrap-report-task .btn-edits {
    background: transparent;
    border: 0;
    cursor: pointer;
}

.wrap-report-task .btn-edits svg {
    width: 20px;
}

.wrap-report-task .text-report-selected-task .text-report-tasks {
    padding-left: 17px;
}

.wrap-report-task .text-report-selected-task .text-report-tasks p {
    font-size: 13px;
    line-height: 20px;
}

.task_report_modal {
    overflow-y: auto;
    height: 100%;
}

.task_report_modal .header_modal {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.task_report_modal .header_modal .heading--active {
    margin-bottom: 0;
}

.task_report_modal .header_modal button {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #016288;
}

.task_report_modal .body_modal {
    padding: 15px;
    border-top: 1px solid #F5F6F8;
}

.task_report_modal .body_modal .label_task_modal {
    font-size: 15px;
    color: #334D6E;
    font-weight: 600;
    margin-bottom: 5px;
}

.texteditor-report-task {
    margin: 5px 0 15px;
}

.texteditor-report-task .container {
    padding: 0 0;
}

.texteditor-report-task .mde-header .mde-tabs button {
    padding: 0 9px;
}

.footer_modal {
    padding: 15px;
    border-top: 1px solid #E5E8EF;
}

.footer_modal button {
    background: #FCAF18;
    border: 1px solid #FCAF18;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 15px;
    margin-top: 0;
    font-weight: 600;
    color: #016288;
}

.footer_modal button:hover {
    background: transparent;
    color: #FCAF18;
}

.report-calendar .fc-col-header,
.report-calendar .fc-scrollgrid-sync-table,
.report-calendar .fc-daygrid-body.fc-daygrid-body-unbalanced {
    width: 100% !important;
}

/* create-project over */

@media screen and (max-width:991px) {
    .block-overview{
        padding-bottom: 15px;
    }
    .progress-swiper>.swiper-container-horizontal>.swiper-pagination-bullets,
    .progress-swiper>.swiper-pagination-custom,
    .progress-swiper>.swiper-pagination-fraction {
        z-index: 5;
    }
    .bottom-text{
        flex-direction: column;
    }
    .project-status-div .grid-view-drag{
        min-width: 230px;
    }
}

@media screen and (max-width:767px) {
    .overview-wrap {
        margin: 0 17px 12px 0;
        /* width: 100%; */
    }

    .overview-list {
        display: inline-block;
    }

    .overview-list .overview-badge {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        box-shadow: unset;

    }

    .workflow-btn {
        padding: 12px 15px;
        width: unset;

    }

    .project-status-div {
        margin: 0px 17px 15px 0px;
    }

    .project-status-div .grid-view-drag {
        min-width: 254px;
    }

    .progress-swiper {
        border-left: 0;
        border-top: 1px solid #E5E8EF;
        margin-bottom: 40px;
        border-bottom: 0;
    }

}

@media screen and (max-width:425px) {
    .overview-list {
        display: flex;
        flex-wrap: wrap;
        column-count: 2;
        grid-column-gap: 40px;
        column-gap: 40px;
    }

    .overview-list .overview-badge .overview-list-title {
        margin: 10px 0 0;
    }
}

@media screen and (max-width:375px) {
    .overview-list {
        column-gap: 30px;
    }

    .overview-list .overview-badge {
        max-width: 128px;
    }
}

@media screen and (max-width:320px) {
    .overview-list {
        column-gap: 20px;
    }

    .overview-list .overview-badge {
        padding: 12px;
        max-width: 105px;
    }
    .block-overview{
        padding-bottom: 15px;
    }
}

/* @media screen and (max-width:768px) {
    .overview-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@media screen and (max-width:425px) {
    .overview-list {
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .overview-list .overview-badge {
        margin-bottom: 20px;
    }

    .bottom-text {
        flex-direction: column;
    }


} */