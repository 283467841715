.sidebar-container {
    width: 100%;
    max-width: 236px;
    background: #F5F6F8;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
}

.sidebar-container.mobile-sidebar {
    left: -300px;
    z-index: 10;
    top: 65px;
    transition: 0.3s linear;
}

.sidebar-container.mobile-sidebar.is_active {
    left: 0;
}

.sidebar-wrap-container {
    padding: 25px 13px 25px 15px;
}

.sidebar-container .thumb-scroll-track-vertical {
    display: none;
}

.sidebar-container:hover .thumb-scroll-track-vertical {
    display: inline-block;
}

.logo-wrap {
    text-align: center;
    padding: 11px 0 0;
}

.profile-auth-wrap {
    width: 100%;
    text-align: center;
    margin: 24px 0 19px;
    display: inline-block;
}

.profile-auth-wrap .image-users-wrap {
    width: 72px;
    height: 72px;
    display: block;
    border-radius: 50%;
    margin: 0 auto 8px;
    position: relative;
}

.profile-auth-wrap .img-wrap-user {
    width: 72px;
    height: 72px;
    display: block;
    overflow: hidden;
    border-radius: 50%;
}

.profile-auth-wrap .img-wrap-user img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-auth-wrap .img-wrap-user .user-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #FFF;
    font-size: 25px;
    color: #016288;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-auth-wrap .image-users-wrap .online-notify {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 10px;
    bottom: 0;
    background: #FCAF18;
    border: 2px solid #F5F6F8;
}

.profile-auth-wrap .image-users-wrap .online-notify.active {
    background: #0ac963;
}

.user-name {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #192A3E;
    margin: 0 0 8px;
}

.user-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #90A0B7;
    margin: 0;
}

.nav-wrap {
    width: 100%;
}

.main-nav-ul {
    padding-left: 0;
    width: 100%;
    list-style: none;
    margin-bottom: 27px;
    display: inline-block;
}

.main-nav-ul li {
    list-style: none;
    width: calc(100% / 2);
    float: left;
    text-align: center;
}

.main-nav-ul li a {
    padding: 27px 10px;
    background: transparent;
    border: 2px solid #C2CFE0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: relative;
}

/* .main-nav-ul li:nth-child(odd) a{
    border-right: 1px;
} */
.main-nav-ul li a .icon svg path {
    stroke: #7f8da1;
    /* stroke: #C2CFE0; */
}

.main-nav-ul li a .icon.icon-fill svg path {
    fill: #7f8da1;
}

.main-nav-ul li a .text {
    margin-top: 6px;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    color: #7f8da1;
    /* color: #C2CFE0; */
    text-decoration: none;
}

.main-nav-ul li a .noti-unseen {
    position: absolute;
    top: 20%;
    right: 20%;
    transform: translate(-20%, -20%);
    padding: 3px 8px 3px;
    background: #FCAF18;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334D6E;
    z-index: 2;
}

.main-nav-ul li a.active .noti-unseen {
    background: #FFF;
}

.main-nav-ul li a.active {
    background: #FCAF18;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-color: transparent;
}

.main-nav-ul li a.active .icon svg path {
    stroke: #016288;
}

.main-nav-ul li a.active .icon.icon-fill svg path {
    fill: #016288;
}

.main-nav-ul li a .icon.icon-fill svg path {
    stroke: unset;
}

.main-nav-ul li a.active .text {
    color: #016288;
    font-weight: bold;
}

.trash-button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid #016288;
    background: transparent;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: normal;
    line-height: 17px;
    text-decoration: none;
    max-width: 91px;
    color: #016288;
}

.trash-button svg {
    margin-right: 8px;
}

.trash-button.active {
    background: #FCAF18;
    /* background: #FFF; */
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.select-options-wrap {
    padding: 0 12px;
}

.drive-wraps {
    width: 100%;
    margin-top: 20px;
    background: #FFF;
    padding: 12px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    position: relative;
}

.drive-wraps .icon-wraps {
    width: 100%;
}

.drive-wraps .close-btn {
    background: transparent;
    padding: 0;
    border: 0;
    position: absolute;
    right: 12px;
    top: 12px;
}

.drive-wraps .text-drive {
    margin: 26px 0 0;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #334D6E;
}

/* .drive-wraps :not(button) svg path:nth-child(0) {
    fill: red!important;
} */

.microsoft-btn{
    /* border:1px solid red!important */
    border-radius: 6px!important;
    height: 40px;
    
}

.microsoft-btn path:nth-child(1) {
 fill:#016288;
 height: 40px;
}