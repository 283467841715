.trash-section {
    padding: 39px 30px 0;
}

.page-title-wrap.trash-page {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.trash-page .page-title {
    font-size: 28px;
    font-weight: 700;
    margin-right: 230px;
}

.page-title-wrap.trash-page .search-input {
    width: 100%;
    max-width: 330px;
    background: #F5F6F8 url("../images/search-icon.svg") no-repeat center left 16px;
}

.link-page-title-wrap {
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 32px;
}

.link-page-title-wrap button {
    background: transparent;
    padding: 0;
    border: 0;
    line-height: 0;
    margin-right: 8px;
    font-size: 25px;
    color: #C2CFE0;
}

.trash-title {
    color: rgba(1, 98, 136, 1);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 4px;
}

.link-page-title-wrap img {
    width: 19px;
}

.page-btns-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.page-btns-wrap .page-btn {
    color: white;
    background: rgba(1, 98, 136, 1);
    font-size: 12px;
    font-weight: 400;
    padding: 8px 22px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 0px;
    margin-right: 8px;
}
.page-btns-wrap .restore,
.page-btns-wrap .removePermenently{
    display: flex;
    align-items: center;
    padding: 8px 22px;
}
.page-btns-wrap .restore span,
.page-btns-wrap .removePermenently span{
    line-height: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
}
.page-btn .restore {
    background: url('../images/restore-icon.svg') no-repeat;
}

.page-btn .removePermenently {
    background: url('../images/restore-icon.svg') no-repeat;
}

.setting-btn {
    background: transparent;
    display: inline-block;
    margin-left: auto;
    border: none;
    width: 30px;
}

.table {
    padding-bottom: 8px !important;
}

.table, th, td {
    border: 0px;
}


.table>thead {
    border: 0px;
    border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
    border-bottom: 0px;
    border-bottom: 2px solid rgba(245, 246, 248, 1);
    background-color: rgba(245, 246, 248, 1);
}

.table>thead tr th {
    color: rgba(194, 207, 224, 1);
    padding: 12px 0px 12px 34px;
    border: none;
}

.table>tbody>tr>td {
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 77, 110, 1);
    padding: 24px 0px 24px 34px;

}

.table>tbody>tr {
    border: 1px solid rgba(245, 246, 248, 1);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.table>tbody>tr:hover {
    border: 0px;
    border-radius: 8px;
    border: 2px solid rgba(1, 98, 136, 1);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.table>tbody>tr>td {
    margin-bottom: 8px !important;
}

.table>thead>tr>td>input {
    font-size: 20px;
    font-weight: 400;
    display: inline-block;
    border: 1px solid rgba(194, 207, 224, 1);
    padding: 24px auto;
}


.table>tbody>tr>td>input {
    font-size: 20px;
    font-weight: 400;
    border: 1px solid rgba(194, 207, 224, 1);
    padding: 24px 0;
}


.folder-icon {
    margin-right: 8px;
}

/* new css */

.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

}

.swiper-button-prev::after {
    content: "prev";
    font-size: 15px;
    padding: 8px;
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
    color: rgba(112, 124, 151, 1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.swiper-button-next::after {
    content: "next";
    font-size: 15px;
    padding: 8px;
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
    color: rgba(112, 124, 151, 1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.file-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.file-title {
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(1, 98, 136, 1);
    margin-bottom: 0;
}

.dropdown-menu {
    padding: 0px !important;
}

.custom-dropdown {
    padding: 12px;
    box-shadow: -5px 6px 24px 0px rgba(0, 0, 0, 0.1);
    outline: none;
    border: 0px;
}

.file-dropdown-text {
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    margin-right: 0px;
    text-align: left;
    color: rgba(1, 98, 136, 1);
    outline: none;
}


.css-26l3qy-menu {
    z-index: 99 !important;
    padding: 12px;
}

.css-26l3qy-menu label {
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em; 
    margin-right: 0px;
    text-align: left;
    color: rgba(1, 98, 136, 1);
    outline: none;
}
.confirm-modals{
    padding: 10px;
}