.reminder-page-wrap {
    padding: 0px 24px;
}

.page-top-header.reminder {
    padding: 31px 24px 15px;
}

.due-soon-wrap {
    background: rgba(245, 246, 248, 1);
    border-radius: 12px;
    padding: 13px 17px 25px 23px;
    margin-bottom: 32px;
    width: 100%;
    height: 20%;
    overflow: hidden;
    /* overflow-y: auto; */
    overflow-x: auto;
}

.due-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(51, 77, 110, 1);
}

.due-badge-list {
    display: flex;
    margin-right: 20px;
}

.due-badge-wrap {
    padding: 12px 35px 12px 17.32px;
    background: #fff;
    margin-right: 20px;
    border-radius: 12px;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.06);
}

.due-time {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(1, 98, 136, 1);
    margin-bottom: 10px;
}

.due-status {

    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 10px;
    color: rgba(1, 98, 136, 1);

}



.reminder-right-bar-wrap {
    /* padding: 0px 24px; */
    width: 100%;
    max-width: 285px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    right: 0;
    top: 88px;
    z-index: 1;

}

.reminder-list-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(51, 77, 110, 1);
    margin-bottom: 24px;
    padding: 0 24px;
}

.reminder-list {
    margin: 0px;
    padding: 0px;
    height: calc(100vh - 185px);
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 5px;
}

.reminder-list li {
    list-style: none;
    padding: 0 10px 0 0;
}

.reminder-list-wrap {
    border: 1px solid rgba(194, 207, 224, 1);
    padding: 19px 20px;
    margin-bottom: 20px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;

}

.reminder-list-wrap .reminder-time {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 0px !important;
    color: rgba(1, 98, 136, 1);
}

.mb-12 {
    margin-bottom: 12px;
}

.reminder-list-status {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(1, 98, 136, 1);
    margin-bottom: 48px;

}

.reminder-user-img-wrap {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    overflow: hidden;
}

.reminder-user-img-wrap img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.reminder-user-img-wrap .user-icon,
.due-user-img-wrap .user-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #F5F6F8;
    font-size: 10px;
    color: #016288;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.due-user-img-wrap .user-icon {
    font-size: 8px;
}

.reminder-btn {
    display: flex;
    background: #016288;
    border-radius: 6px;
    padding: 27px 51px !important;
    font-family: 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    border: 0;
    margin: 15px 0 0;
}

.reminder-btn img {
    margin-right: 8px;
}

.reminder-btn-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #fff;
    display: flex;
    margin-bottom: 0px;

}

.reminder-btn.ismobile-btn {
    margin-top: 0;
    padding: 10px !important;
    margin-right: 10px;
    border-radius: 25px;
}

.reminder-drop .dropdown-toggle::after {
    display: none !important;
}

.reminder-drop .dropdown-toggle {
    padding: 0px !important;
    background: #FFFFFF;
}

.reminder-drop .dropdown-toggle:focus {
    background: transparent !important;
}

.reminder-drop .dropdown-toggle::after:focus {
    display: none !important;
}

.due-user-li {
    margin: 0px;
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    text-align: left;
}

.due-user-li li {
    list-style: none;
    margin-left: -6px !important;
    padding: 0;
}

.due-user-li li:first-child {
    margin-left: 0px !important;
}

.due-user-li .due-user-img-wrap {
    width: 19px;
    height: 19px;
    max-width: 19px;
    max-height: 19px;
    border-radius: 50%;
    overflow: hidden;
}

.due-user-li .due-user-img-wrap img {
    width: 100%;
    object-fit: cover;
}

.reminder-dropdown {
    background: #fff !important;
}

.reminder-dropdown-menu {
    position: absolute;
    inset: 0px 0px auto auto;
    border: 0px;
    padding: 11px 13px !important;
    border-radius: 6px;
    transform: translate3d(-20.8px, 2.8px, 0px) !important;
    -webkit-transform: translate3d(-20.8px, 2.8px, 0px) !important;
    -moz-transform: translate3d(-20.8px, 2.8px, 0px) !important;
    -ms-transform: translate3d(-20.8px, 2.8px, 0px) !important;
    -o-transform: translate3d(-20.8px, 2.8px, 0px) !important;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    box-shadow: -5px 6px 24px 0px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    align-items: center;
}

.dropdown-item:last-child {
    margin-bottom: 0px;
}

.dropdown-item img {
    margin-right: 10px;
}

.reminder-dropdown-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
    color: rgba(1, 98, 136, 1);
    margin-bottom: 0px;

}

.calender-wrap {
    padding: 24px 19px;
    border: 1px solid rgba(194, 207, 224, 1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}


.fc .fc-prev-button, .fc .fc-next-button {
    border-radius: 50%;
    padding: 3px;
    background-color: #FFF !important;
    margin-right: 5px;
    color: var(--fc-button-text-color, #fff);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border: 0px;
    box-shadow: 0px 5px 25px 0px rgba(42, 139, 242, 0.07);
    box-shadow: 0px 7px 25px 0px rgba(42, 139, 242, 0.03);
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.03);
    -o-border-radius: 50%;
}

.fc .fc-button .fc-icon {
    color: rgba(112, 124, 151, 1);
    font-size: 15px;
    font-weight: 900;
}

.fc-toolbar-chunk>div {
    display: flex;
}

.fc-toolbar-title {
    color: rgba(37, 37, 37, 1);
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: 31px !important;
}

.fc .fc-button-group:last-child {
    background: rgba(245, 246, 248, 1);
    border-radius: 45px;
    padding: 8px 14px;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    -ms-border-radius: 45px;
    -o-border-radius: 45px;
}

.fc .fc-button {
    background: rgba(245, 246, 248);
    border: 0px;
    border-radius: 44px !important;
    padding: 2px 8px !important;
    color: rgba(1, 98, 136, 1);
    -webkit-border-radius: 44px !important;
    -moz-border-radius: 44px !important;
    -ms-border-radius: 44px !important;
    -o-border-radius: 44px !important;
}

.fc .fc-dayGridMonth-button, .fc .fc-timeGridWeek-button, .fc .fc-dayGridDay-button {
    background: none !important;
    border: 0px;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(1, 98, 136, 1) !important;
    -webkit-border-radius: 45px;
    -moz-border-radius: 45px;
    -ms-border-radius: 45px;
    -o-border-radius: 45px;
}

.fc .fc-button-active {
    background: rgba(1, 98, 136, 1) !important;
    color: #FFFFFF !important;
    outline: none !important;
    outline: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
}

.fc .fc-button-primary:focus {
    box-shadow: none !important;
}

/* calender texts */
thead .fc-day {
    padding: 34px;
}

thead .fc-day a {
    color: black;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
}

thead .fc-day a:hover {
    background: transparent !important;
    color: #000 !important;
}

tbody .fc-day a {
    color: black;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
}


thead .fc-day a:hover {
    color: white;
    background: rgba(1, 98, 136, 1);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.fc-day.fc-day-past .fc-daygrid-day-top {
    opacity: 0.3;
}

.reminder-modal-popup .skylight-dialog {
    width: 100% !important;
    max-width: 550px !important;
    margin-top: 0 !important;
    margin-left: 0 !important;
    left: 50% !important;
    top: 50% !important;
    min-height: 0 !important;
    transform: scale(0) translate(-50%, -50%) !important;
    padding: 24px !important;
    border-radius: 12px !important;
}

.reminder-modal-popup.show .skylight-dialog {
    transform: scale(1) translate(-50%, -50%) !important;
}

.reminder-modal-popup .create-reminder__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

}

.reminder-modal-popup .create-reminder__title h3 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #016288;
}

.reminder-modal-popup .create-reminder__title .btn-clear-modal {
    background: transparent;
    border: 0;
    line-height: 0;
    font-size: 25px;
    color: #016288;
    cursor: pointer;
}

.reminder-modal-popup .icon-select {
    position: relative;
    margin-right: 10px;
}

.reminder-modal-popup .icon-select .icon-select__icon {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    padding: 2px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 56px;
    height: 56px;
    cursor: pointer;
}

.reminder-modal-popup .icon-select .icon-select__icon img {
    width: 90%;
    height: 90%;
    object-fit: contain;
}

.reminder-modal-popup .icon-select .icons {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 14px;
    width: 215px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 9px;
    -webkit-column-gap: 9px;
    -moz-column-gap: 9px;
    -o-column-gap: 9px;
    -ms-column-gap: 9px;
    row-gap: 9px;
    grid-row-gap: 9px;
    top: 62px;
    z-index: 2;
}

.reminder-modal-popup .icon-select .icons .icon-select__icon_sub {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05)); */
}

.reminder-modal-popup .icon-select .icons .icon-select__icon_sub.active {
    border: 1px solid #016288;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
}

.reminder-modal-popup .icon-select .icons .icon-select__icon_sub:hover {
    border: 1px solid #C2CFE0;
}

.reminder-modal-popup .icon-select .icons .icon-select__icon_sub img {
    /* width: 80%; */
    height: 80%;
    object-fit: fill;
}

.reminder-modal-popup .top-form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.reminder-modal-popup .top-form-title .title-reminder-create {
    width: 100%;
}

.reminder-modal-popup .top-form-title .title-reminder-create h4 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #334D6E;
}

.reminder-modal-popup .top-form-title .time-containter {
    width: 100%;
}

.reminder-modal-popup .title-control {
    padding: 14px 16px;
    background: #FFFFFF;
    border: 1px solid #C2CFE0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #334D6E;
}

.reminder-modal-popup .title-control::placeholder {
    color: #334D6E;
    opacity: 0.4;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
}

.reminder-modal-popup .radio-wraps-reminder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.reminder-modal-popup .radio-wraps-reminder input[type="radio"] {
    display: none;
}

.reminder-modal-popup .radio-wraps-reminder label {
    padding-left: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #016288;
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
}

.reminder-modal-popup .radio-wraps-reminder label::after {
    content: '';
    width: 16px;
    height: 16px;
    border: 1px solid #016288;
    background: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 1px;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 13px;
}

.reminder-modal-popup .radio-wraps-reminder input[type="radio"]:checked~label::after {
    content: "\2713";
    background: #016288;
    border-color: #016288;
    color: #FFF;
    vertical-align: middle;
}

.reminder-modal-popup .date-time-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 8px;
}

.reminder-modal-popup .date-time-container .time-containter-time {
    width: 100%;
    max-width: 241px;
}

.reminder-modal-popup .date-time-container .time-containter-time label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #334D6E;
    margin-bottom: 8px;
}

.reminder-modal-popup .create-reminder__textarea {
    width: 100%;
    margin: 24px 0 16px;
}

.reminder-modal-popup .create-reminder__textarea label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #334D6E;
    margin-bottom: 8px;
}

.reminder-modal-popup .create-reminder__btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.reminder-modal-popup .create-reminder__btns button {
    border-radius: 6px;
    padding: 17.5px 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    border: 1px solid transparent;
    background: transparent;
}

.reminder-modal-popup .create-reminder__btns button:first-child {
    margin-left: 0;
}

.reminder-modal-popup .create-reminder__btns button.btn-cancel {
    border-color: #C2CFE0;
    background: #F5F6F8;
    color: #016288;
}

.reminder-modal-popup .create-reminder__btns button.delete {
    border-color: #e0c2c3;
    background: #f8f5f5;
    color: #880101;
}

.reminder-modal-popup .create-reminder__btns button.create {
    border-color: #016288;
    background: #016288;
    color: #fff;
}

.reminder-modal-popup .create-reminder__btns button.btn-secondary {
    border-color: #FCAF18;
    background: #FCAF18;
    color: #016288;
}

.reminder-modal-popup .create-reminder__btns button.btn-secondary:hover {
    color: #016288;
}

.show-reminder {
    width: 100%;
    padding: 25px;
}

.show-reminder .show-reminder__time {
    font-size: 25px;
    color: #000;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 25px;
}

.show-reminder .show-reminder__items {
    width: 100%;
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 25px;
}

.show-reminder .show-reminder__items .show-reminder__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 10px;
    padding: 5px 10px 5px 15px;
    background: rgba(1, 98, 136, 0.05);
    cursor: pointer;
}

.show-reminder .show-reminder__items .show-reminder__item:last-child {
    margin-bottom: 0;
}

.show-reminder .show-reminder__items .show-reminder__item .stick {
    width: 3px;
    background-color: #016288;
    opacity: .8;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.show-reminder .show-reminder__items .show-reminder__item .info__time {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    line-height: 15px;
    margin-bottom: 5px;
}

.show-reminder .show-reminder__items .show-reminder__item .info__title {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    line-height: 20px;
    margin-bottom: 0px;
}

.show-reminder .show-reminder__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.show-reminder .show-reminder__action button {
    background: #FCAF18;
    color: #016288;
    padding: 8px 15px;
    border-radius: 5px;
    border: 1px solid #FCAF18;
    font-size: 15px;
    font-weight: 600;
}

.show-reminder .show-reminder__action button:hover {
    background: transparent;
    color: #FCAF18;
}

@media screen and (max-width:991px) {
    .reminder-right-bar-wrap {
        position: relative;
        top: inherit;
        right: inherit;
        max-width: 100%;
        margin-top: 25px;
    }

    .calender-wrap .fc-view-harness-active>.fc-view {
        overflow-x: auto !important;
    }

    .calender-wrap .fc-view-harness-active>.fc-view .fc-scrollgrid.fc-scrollgrid-liquid {
        table-layout: unset !important;
    }

    .calender-wrap .fc-view-harness-active>.fc-view .fc-scrollgrid.fc-scrollgrid-liquid tbody .fc-scroller.fc-scroller-liquid-absolute {
        position: relative;
        top: inherit;
        right: inherit;
    }

    .calender-wrap thead a {
        font-size: 14px;
    }
}

@media screen and (max-width:425px) {
    .fc .fc-toolbar {
        flex-direction: column;
    }

    .fc .fc-toolbar .fc-toolbar-chunk>div {
        margin-bottom: 10px;
    }

    .fc .fc-toolbar .fc-toolbar-chunk>div .fc-toolbar-title {
        font-size: 15px;
    }

    .fc .fc-toolbar .fc-toolbar-chunk>div .fc-prev-button,
    .fc .fc-toolbar .fc-toolbar-chunk>div .fc-next-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
    }

    .calender-wrap .fc-view-harness {
        height: inherit !important;
    }

    .calender-wrap .fc-view-harness-active>.fc-view {
        position: relative;

    }

    .calender-wrap thead a {
        font-size: 14px;
    }

    .calender-wrap thead .fc-day {
        padding: 0;
    }
}